import styles from './FeaturedFlightsMainPage.module.scss';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import cn from 'classnames';
import { ImageWithFallback } from '../ImageWithFallback/ImageWithFallback';
import CurrencyFormat from 'react-currency-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
// import { IconButton } from '@material-ui/core';
// import CloseIcon from '@mui/icons-material/Close';
// import FlightSearch from '../FlightSearch/FlightSearch';
import moment from 'moment/moment';
import { getCabinClass } from '../FlightSearchResults/utils/flightUtils';
import { useWindowWidth } from '@react-hook/window-size';
import { BCCarousel } from '../Carousel/Carousel';

// Old version of FeaturedFlights component (will be replaced in future)
export const FeaturedFlightsMainPage = ({
  featuredOrigin,
  featuredFlights,
  setFeaturedOrigin,
}) => {
  // const [openFeaturedFlightDialog, setOpenFeaturedFlightDialog] = useState({
  //   open: false,
  //   flightSearchMeta: null,
  //   airline: null,
  // });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const width = useWindowWidth();

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  // function generateOffer(flight, airline) {
  //   return {
  //     from: flight.origin,
  //     to: flight.destination,
  //     dateRange: {
  //       departure: flight.departureDate,
  //       return: flight.returnDate,
  //     },
  //     cabinClass: 'BIZ',
  //     tripState: 'round_trip',
  //     airlineType: airline,
  //   };
  // }

  function getFeaturedFlightLongestSegment(flight) {
    const flightSegments = flight.trip.legs.map((leg) => leg.segments).flat();
    const longestDuration = Math.max(...flightSegments.map((s) => s.duration));
    return flightSegments.find((s) => s.duration === longestDuration);
  }

  function getCityImage(flight) {
    return (
      featuredFlights.cityImages?.[flight.destination] ||
      featuredFlights.cityImages?.['000']
    );
  }
  function msToTime(duration) {
    const minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor(duration / (1000 * 60 * 60));
    return { hours, minutes };
  }
  function hoursAgo(searchDate) {
    const diff = msToTime(
      new Date() -
        new Date(
          searchDate?.replace(' ', 'T')?.replace(' UTC', '')?.split('.')[0] +
            'Z'
        )
    );
    const minutes = diff.minutes;
    const hours = diff.hours;
    if (hours >= 1) {
      if (hours > 1) {
        return hours + ' hours ago';
      } else {
        return hours + ' hour ago';
      }
    } else {
      if (minutes === 1) {
        return minutes + ' minute ago';
      } else if (minutes === 0) {
        return ' just now';
      } else {
        return minutes + ' minutes ago';
      }
    }
  }

  function groupForDesktop(featuredFlights) {
    return featuredFlights.length > 3 && featuredFlights.length < 6
      ? featuredFlights.slice(0, 3)
      : featuredFlights;
  }

  return (
    <>
      <div className={styles.featuredFlightTitle}>
        Best Flight Offers From{' '}
        {featuredOrigin.origin?.length > 1 ? (
          <div className={'inline-block'}>
            <a aria-describedby={id} onClick={handleClick}>
              {featuredOrigin.city[featuredOrigin.selectedOrigin] ||
                featuredOrigin.selectedOrigin}
              <KeyboardArrowDownRoundedIcon />
            </a>
          </div>
        ) : (
          <span>
            {featuredOrigin.city[featuredOrigin.selectedOrigin] ||
              featuredOrigin.selectedOrigin}
          </span>
        )}
        <ClickAwayListener
          onClickAway={(evt) => {
            if (evt.target.name !== name && evt.target.tagName !== 'BODY') {
              handleClick();
            }
          }}
        >
          <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 3 }}>
            <Box
              sx={{
                borderRadius: '12px',
                p: 1,
                bgcolor: 'background.paper',
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))',
              }}
            >
              {featuredOrigin.origin.map((o) => {
                return (
                  <div
                    key={o}
                    className={styles.featuredOrigin}
                    onClick={() => {
                      setFeaturedOrigin({
                        ...featuredOrigin,
                        selectedOrigin: o,
                      });
                      handleClick();
                    }}
                  >
                    {featuredOrigin.city[o] || o}
                  </div>
                );
              })}
            </Box>
          </Popper>
        </ClickAwayListener>
      </div>
      {width > 1024 && (
        <div className={styles.featuredFlights}>
          {groupForDesktop(featuredFlights.flights).map((flight) => (
            <div
              className={styles.featuredFlight}
              key={flight.origin + 'to' + flight.destination + flight.lowTotal}
            >
              <a
                // onClick={(e) => {
                //   const airlineCode =
                //     getFeaturedFlightLongestSegment(flight).airlineCode;
                //   setOpenFeaturedFlightDialog({
                //     open: true,
                //     flightSearchMeta: generateOffer(
                //       flight,
                //       featuredFlights.airlines[airlineCode]
                //     ),
                //     airline: {
                //       name: featuredFlights.airlines[airlineCode],
                //       code: airlineCode,
                //     },
                //   });
                //   e.stopPropagation();
                //   e.preventDefault();
                // }}
                href={'/?origin=' + flight.origin + '&destination=' + flight.destination + '&cabinClass=BIZ'
                }
                className={cn(styles.card)}
              >
                <div className={styles.recomendationContainer}>
                  <div className={cn(styles.icon)}>
                    {featuredFlights.airlineLogos && (
                      <img
                        src={
                          featuredFlights.airlineLogos[
                            getFeaturedFlightLongestSegment(flight).airlineCode
                          ]
                        }
                      />
                    )}
                  </div>
                  <div className="h-full flex">
                    <div className={styles.imageContainer}>
                      <ImageWithFallback
                        src={
                          process.env.mediaUrl +
                          getCityImage(flight).replace('/', '')
                        }
                        width={719}
                        height={719}
                        quality={50}
                        objectFit="cover"
                      />
                    </div>
                    <div
                      className={cn(
                        styles.text,
                        'relative flex flex-col justify-center items-start'
                      )}
                    >
                      <div
                        className={cn(styles.type, 'font-libre inline-flex')}
                      >
                        <span className={styles.airline}>
                          {
                            featuredFlights.airlines[
                              getFeaturedFlightLongestSegment(flight)
                                .airlineCode
                            ]
                          }
                        </span>
                        {' | '}
                        {getCabinClass(
                          getFeaturedFlightLongestSegment(flight).cabinClass,
                          false
                        )}
                      </div>
                      <div className={cn(styles.title, 'font-fragment')}>
                        {featuredFlights.city[flight.destination] ||
                          flight.destination}
                        <br />
                        <div className={cn(styles.type, 'font-libre mb-2')}>
                          {moment(flight.departureDate).format('D. MMM')} -{' '}
                          {moment(flight.returnDate).format('D. MMM yyyy')}
                        </div>

                        <div className={cn(styles.price, 'mb-1 mt-2')}>
                          <CurrencyFormat
                            thousandSeparator
                            displayType={'text'}
                            prefix={
                              getSymbolFromCurrency(flight.lowTotalCurrency) +
                              ' '
                            }
                            value={Math.round(flight.lowTotal)}
                          />
                          *
                        </div>
                        <div
                          className={cn(styles.type, 'font-libre mb-auto mr-2')}
                        >
                          Offer found {hoursAgo(flight.searchDate)}{' '}
                          {process.env.prod === 'false' && (
                            <div>
                              <div>unique count: {flight.uniqueTotalCount}</div>
                              <div>price count: {flight.priceTotalCount}</div>
                              <div>Type: {flight.type}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      )}
      {width <= 1024 && (
        <div className={styles.featuredFlightsMobile}>
          <BCCarousel
            showThumbs={false}
            disableFullscreen
            alwaysShowPrevious={true}
            customArrowPos={{ top: 225, buttonOffset: 0 }}
            showIndicators={true}
            fullHD={false}
            infiniteLoop={true}
            autoPlay={true}
            renderIndicator={(clickHandler, isSelected, index) => (
              <span
                onClick={clickHandler}
                style={{ left: index ? index * 20 + 50 + 'px' : 50 }}
                className={cn(styles.carouselIndicator, {
                  [styles.selected]: isSelected,
                })}
              />
            )}
            interval={5000}
          >
            {featuredFlights.flights.map((flight) => (
              <div
                className={styles.featuredFlightMobile}
                key={
                  flight.origin + 'to' + flight.destination + flight.lowTotal
                }
              >
                <a
                  href={'/?origin=' + flight.origin + '&destination=' + flight.destination + '&cabinClass=BIZ'}
                  // onClick={(e) => {
                  //   const airlineCode =
                  //     getFeaturedFlightLongestSegment(flight).airlineCode;
                  //   setOpenFeaturedFlightDialog({
                  //     open: true,
                  //     flightSearchMeta: generateOffer(
                  //       flight,
                  //       featuredFlights.airlines[airlineCode]
                  //     ),
                  //     airline: {
                  //       name: featuredFlights.airlines[airlineCode],
                  //       code: airlineCode,
                  //     },
                  //   });
                  //   e.stopPropagation();
                  //   e.preventDefault();
                  // }}
                  className={cn(styles.card)}
                >
                  <div className={styles.recomendationContainer}>
                    <div className="h-full flex flex-col">
                      <div className={styles.imageContainer}>
                        <ImageWithFallback
                          src={
                            process.env.mediaUrl +
                            getCityImage(flight).replace('/', '')
                          }
                          layout={'fill'}
                          quality={50}
                          objectFit="cover"
                        />
                      </div>
                      <div
                        className={cn(
                          styles.text,
                          'relative flex flex-col justify-center items-start'
                        )}
                      >
                        <div className={cn(styles.icon)}>
                          {featuredFlights.airlineLogos && (
                            <img
                              src={
                                featuredFlights.airlineLogos[
                                  getFeaturedFlightLongestSegment(flight)
                                    .airlineCode
                                ]
                              }
                            />
                          )}
                        </div>

                        <div className={cn(styles.type, 'font-libre ')}>
                          <span className={styles.airline}>
                            {
                              featuredFlights.airlines[
                                getFeaturedFlightLongestSegment(flight)
                                  .airlineCode
                              ]
                            }
                          </span>
                          {' | '}
                          {getCabinClass(
                            getFeaturedFlightLongestSegment(flight).cabinClass,
                            false
                          )}
                        </div>
                        <div className={cn(styles.title, 'font-fragment')}>
                          {featuredFlights.city[flight.destination] ||
                            flight.destination}
                          <br />
                          <div className={cn(styles.type, 'font-libre mb-2')}>
                            {moment(flight.departureDate).format('D. MMM')} -{' '}
                            {moment(flight.returnDate).format('D. MMM yyyy')}
                          </div>
                          <div className={cn(styles.price, 'mb-1 mt-2')}>
                            <CurrencyFormat
                              thousandSeparator
                              displayType={'text'}
                              prefix={
                                getSymbolFromCurrency(flight.lowTotalCurrency) +
                                ' '
                              }
                              value={Math.round(flight.lowTotal)}
                            />
                            *
                          </div>
                          <div
                            className={cn(
                              styles.type,
                              'font-libre mb-auto mr-2'
                            )}
                          >
                            Offer found {hoursAgo(flight.searchDate)}{' '}
                            {process.env.prod === 'false' && flight.count}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </BCCarousel>
        </div>
      )}
      {/* <div className={styles.disclaimer}>
        *Fares displayed have been collected within the last 72hrs and may no
        longer be available at time of booking.
      </div> */}
      {/* <Dialog
        open={openFeaturedFlightDialog.open}
        disableEscapeKeyDown
        fullScreen={width <= 1024}
        onBackdropClick={() =>
          setOpenFeaturedFlightDialog({
            airline: null,
            flightSearchMeta: null,
            open: false,
          })
        }
        maxWidth={'lg'}
      >
        <div className={styles.featuredFlightSearchContainer}>
          <IconButton
            color="inherit"
            onClick={() =>
              setOpenFeaturedFlightDialog({
                airline: null,
                flightSearchMeta: null,
                open: false,
              })
            }
            aria-label="close"
            className={styles.closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <FlightSearch
            contentSearch
            airlineClass={'BIZ'}
            airlineName={openFeaturedFlightDialog.airline?.name}
            airlineCode={openFeaturedFlightDialog.airline?.code}
            flightSearchValueProp={openFeaturedFlightDialog.flightSearchMeta}
            collapsed={false}
            viewMode={width > 1024 ? 'mainSearch' : 'sideSearch'}
          />
          <div className={styles.hint}>
            *Modifying this information may result in a different fare
          </div>
        </div>
      </Dialog> */}
    </>
  );
};
